
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

$(function() {
    $('.moreToggle').click(function(){
        $(this).text(function(i,old){
            return old=='Leer más' ?  'Leer menos' : 'Leer más';
        });
    });
});
